
import { defineComponent, PropType } from "vue-demi";
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import '@/assets/css/slider.css';
import { User } from "@/interfaces/user/user.dto";

export default defineComponent({
  name: 'ProfileEducationMain',
  props: {
    user: {
      type: Object as PropType<User.Dto>,
      required: true
    }
  },
  computed: {
    slider() {
      let images = [] as string[];
      for (let i = 0; i < 25; i++) {
        images = [...images, `/images/education/${i}.png`];
      }
      return images;
    }
  },
  methods: {
    submit() {
      this.$router.push('/partner/test')
    }
  },
  components: {
    Carousel, Slide, Pagination, Navigation
  }
})
