
import { defineComponent } from "vue-demi";
import EducationMain from "@/components/profile/education/index.vue";

export default defineComponent({
  name: "EducationView",
  components: {
    EducationMain,
  },
});
